.slide-up{
    animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}
.slide-down{
    animation: slide-down 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}
.slide-down-2{
    animation: slide-down-2 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}

    
@keyframes slide-up {
    0%{
        transform: translateY(100px)
    }
    100%{
    transform: translateY(0)
  }
    
}

@keyframes slide-down-2 {
    0%{
        transform: translateY(-70px)
    }
    100%{
    transform: translateY(0px)
  }
    
}

@keyframes slide-down {
    0%{
        transform: translateY(-50px)
    }
    100%{
    transform: translateY(0px)
  }
    
}