#tg1{
    width: 20px;
    height: 20px;
    display: block;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
    margin: -10px;
    animation: animateeee 2s infinite;
  }
  
   #tg2{
    width: 20px;
    height: 20px;
    display: block;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
    margin: -10px;
    animation: animateeee 2s infinite;
    animation-delay: -0.2s;
  }
  
  #tg3{
    width: 20px;
    height: 20px;
    display: block;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
    margin: -10px;
    animation: animateeee 2s infinite;
    animation-delay: -0.4s;
  }
  
  @keyframes animateeee {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: rotate(45deg) translate(10px, 10px);
    }
    100% {
      opacity: 0;
    }
  }